<template>
	<ion-button color="primary" href="mailto:freeze-dried-food@lgtron.com" target="_blank">
		{{ $t('tabs.contactUs') }}
	</ion-button>
</template>

<script lang="ts">
	import { IonButton } from "@ionic/vue";

	export default {
		name: "ContactButton",
		components: { IonButton },
	};
</script>

<style lang="scss">
	ion-button {
		margin: 4rem 2rem;
	}
</style>