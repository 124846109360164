
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  mounted() {
    // setTimeout(() => location.href = this.$route.hash, 1);
    console.log(this.$i18n)
  },
});
