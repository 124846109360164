<template>
  <ion-footer>
    <ion-toolbar>
      <ion-label> Copyright © LGtron GmbH 2021</ion-label>
      <ion-buttons slot="secondary">
        <ion-button href="impressum">
          {{ $t("footer.impressum") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import {
  IonFooter,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLabel,
} from "@ionic/vue";

export default {
  name: "Footer",
  components: {IonFooter, IonToolbar, IonButtons, IonButton, IonLabel},
};
</script>

<style lang="scss">
ion-footer {
  margin-top: 5rem;
}

ion-label {
  margin: 1rem;
}
</style>