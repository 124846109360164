<template>
  <img class="lgtron-image" :src="webp" @error="onImageError" :alt="alt"/>
</template>

<script>
export default {
  name: "LgtronImage",
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    webp: function () {
      if (this.error) return this.src;
      return this.src.replace(".jpg", ".webp");
    },
  },
  methods: {
    onImageError: function (e) {
      console.error("image on error: ", e);
      this.error = true;
    }
  }

}
</script>

<style scoped lang="scss">
</style>