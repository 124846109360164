<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="top" selected-tab="start">
        <ion-tab-button tab="start" href="start">
          <ion-label>{{ $t("tabs.start") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="products" href="products">
          <ion-label>{{ $t("tabs.products") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="about" href="about">
          <ion-label>{{ $t("tabs.aboutUs") }}</ion-label>
        </ion-tab-button>

        <LgtronContactButton></LgtronContactButton>

        <ion-select
            value="de"
            v-model="$root.$i18n.locale"
            @ionChange="handleChange"
        >
          <ion-select-option value="de">Deutsch</ion-select-option>
          <ion-select-option value="en">English</ion-select-option>
        </ion-select>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import {LgtronContactButton} from '@/components'
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonSelect, IonSelectOption} from '@ionic/vue';

export default {
  name: 'Tabs',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonSelect, IonSelectOption, LgtronContactButton},
  methods: {
    handleChange(e: CustomEvent) {
      const lang = e.detail.value;
      console.log("change language", this);
      try {
        localStorage.setItem("lgtronLocale", lang);
      } catch (e) {
        /// ignore
      }
    }
  },
}
</script>

<style lang="scss">
ion-tabs {
  ion-tab-button:first-of-type,
  ion-select {
    margin-left: auto;
  }

  ion-label {
    font-size: 1.2rem;
  }

  ion-select {
    padding: 0 1rem;
  }
}
</style>