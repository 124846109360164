
import {
  IonFooter,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLabel,
} from "@ionic/vue";

export default {
  name: "Footer",
  components: {IonFooter, IonToolbar, IonButtons, IonButton, IonLabel},
};
