import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import Tabs from '../views/Tabs.vue'
import { nextTick } from 'vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/start'
    },
    {
        path: '/',
        component: Tabs,
        children: [
            {
                path: '',
                redirect: '/start'
            },
            {
                path: 'start',
                component: () => import('@/views/Start.vue')
            },
            {
                path: 'products',
                component: () => import('@/views/Products.vue')
            },
            {
                path: 'about',
                component: () => import('@/views/About.vue')
            },
            {
                path: 'impressum',
                component: () => import('@/views/Impressum.vue')
            }

        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        console.log("scroll data: ", {to, from, savedPosition});
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        el: to.hash
                    });
                }, 1000)
                const anchorEl = document.querySelector(to.hash);
                if (anchorEl) {
                    setTimeout(() => {
                        anchorEl.scrollIntoView({
                            behavior: "smooth"
                        })
                    }, 100);
                }
            });
        }
    },
    routes
})

export default router
