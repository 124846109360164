
import {LgtronContactButton} from '@/components'
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonSelect, IonSelectOption} from '@ionic/vue';

export default {
  name: 'Tabs',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonSelect, IonSelectOption, LgtronContactButton},
  methods: {
    handleChange(e: CustomEvent) {
      const lang = e.detail.value;
      console.log("change language", this);
      try {
        localStorage.setItem("lgtronLocale", lang);
      } catch (e) {
        /// ignore
      }
    }
  },
}
